import { useCallback, useEffect, useMemo, useState } from 'react'
import { SmartBannerContext } from './Context'
import SmartBannerBottomModal from './SmartBannerBottomModal'
import { useCookies } from 'react-cookie'
import { api } from 'config/apiClient'
import { get } from 'lodash'
import useProfile from 'hooks/useProfile'

type Props = {
  children?: React.ReactNode
}

export const SmartBannerProvider = ({ children }: Props) => {
  const [sessionCookie] = useCookies(['session'])
  const [profile] = useProfile()
  const [open, setOpen] = useState(false)

  const [journeyStep, setJourneyStep] = useState<string | null | undefined>(
    profile ? undefined : null
  )

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  const value = useMemo(() => {
    return {
      openModal,
      closeModal,
      journeyStep,
    }
  }, [openModal, closeModal, journeyStep])

  useEffect(() => {
    const getJourneyStep = async () => {
      const getJourneyStep = await api('getJourneyStep', sessionCookie.session)

      setJourneyStep(get(getJourneyStep, 'data.journeyStep'))
    }

    // istanbul ignore if
    if (typeof window === 'undefined') {
      return
    }

    if (journeyStep === undefined) {
      getJourneyStep()
    }
  }, [sessionCookie, journeyStep])

  return (
    <SmartBannerContext.Provider value={value}>
      {children}
      {journeyStep !== undefined && <SmartBannerBottomModal open={open} />}
    </SmartBannerContext.Provider>
  )
}

import { styled } from 'styles/stitches.config'

export const Header = styled('header', {
  alignItems: 'center',
  backgroundColor: '$grey50',
  color: '$grey1000',
  display: 'flex',
  fontSize: '$heading',
  fontWeight: 'bold',
  margin: '0 auto',
  maxWidth: '100%',
  position: 'relative',
  top: 0,
  width: '$applicationWidth',
})

export const Main = styled('main', {
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
})

export const Section = styled('section', {
  backgroundColor: '$grey50',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  paddingBottom: 'var(--joe-sizes-navHeight)',
  position: 'relative',
  transform: 'none',
  width: '100%',
  zIndex: '$0',

  variants: {
    disableNav: {
      true: {
        paddingBottom: 0,
      },
    },
    header: {
      standard: {
        ['& > header']: {
          height: 56,
        },
      },
      tall: {
        ['& > header']: {
          height: 72,
        },
      },
    },
    noShadow: {
      true: {
        ['& > header']: {
          boxShadow: 'none'
        },
      }
    },
    borderHeader: {
      true: {
        ['& > header']: {
          borderBottom: '1px solid $grey150',
        },
      }
    }
  },
}, {minHeight: '100dvh'})

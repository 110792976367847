import { Elements as StripeProvider } from '@stripe/react-stripe-js'
import { loadStripe, PaymentRequest } from '@stripe/stripe-js'
import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { PaymentMethod } from 'components/PaymentMethod'
import FormSelectModal from 'components/Form/FormSelectModal'
import * as Icon from 'components/DesignSystemIcons'
import { useModal } from 'components/ModalKit'

import { styled } from 'styles/stitches.config'
import useProfile from 'hooks/useProfile'
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form'
import { isEmpty } from 'lodash'
import {
  getPaymentMethod,
  isDefaultPaymentMethod,
  sortPaymentMethods,
} from 'utils/paymentMethod'

import {
  APPLE_PAY_NATIVE,
  APPLE_PAY_WEB,
  GOOGLE_PAY_NATIVE,
  GOOGLE_PAY_WEB,
} from 'config/paymentMethod'
import { useSetPaymentRequest } from 'hooks/useSetPaymentRequest'
import { UserPaymentMethod } from 'types/user'

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
)

type PaymentMethodSelectorProps = {
  name?: string
  options?: RegisterOptions
  paymentMethods: UserPaymentMethod[]
  defaultPaymentMethodId?: string | null
  typeName?: string
  nativePayOnBrowser?: string
  setPaymentRequest?: (paymentRequest: PaymentRequest) => void
  onAddingNewPaymentMethod?: () => void
  onlyCreditCard?: boolean
  excludeDigitalWallets?: boolean
}

const AddNewPaymentButton = styled('div', {
  alignItems: 'center',
  color: '$grey1000',
  display: 'flex',
  padding: '$m',
  width: '100%',
  fontWeight: '$bold',
  gap: '$s',
})

const PlaceholderButton = styled('div', {
  backgroundColor: 'transparent',
  border: '2px solid $red500',
  borderRadius: '$v',
  color: '$red500',
  cursor: 'pointer',
  padding: '$xxs $s !important',
  width: 'fit-content',
  fontWeight: '$bold',
})

const ModalHeader = styled('div', {
  color: '$grey1000',
  fontSize: '$heading2',
  fontWeight: '$extraBold',
  whiteSpace: 'normal',
})

const ErrorMessage = styled('div', {
  color: '$red600',
  marginBlock: '$s',
})

const customStyling = {
  border: 'none',
  margin: '0',
  padding: '$s',
}

const buttonStyling = {
  backgroundColor: 'inherit',
  color: '$grey1000',
  padding: '0',

  '& > span:first-child': {
    textAlign: 'left',
    flexGrow: 1,
    '& > div': {
      padding: 0,
      margin: 0,
    },
  },
}

const PaymentMethodSelector = ({
  name = 'paymentMethod',
  options,
  paymentMethods: initialPaymentMethods,
  defaultPaymentMethodId = null,
  setPaymentRequest,
  onAddingNewPaymentMethod,
  onlyCreditCard,
  excludeDigitalWallets = false,
}: PaymentMethodSelectorProps) => {
  const router = useRouter()
  const [profile] = useProfile()
  const { closeAllModals } = useModal()

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const value = useWatch({ name })
  const modalId = useRef('')
  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods)

  const {
    applePayAvailableWeb,
    applePayAvailableNative,
    googlePayAvailableWeb,
    googlePayAvailableNative,
  } = useSetPaymentRequest({ setPaymentRequest })

  useEffect(() => {
    if (excludeDigitalWallets) {
      return
    }

    const allPaymentMethods = [...initialPaymentMethods]

    if (applePayAvailableNative) {
      allPaymentMethods.push(APPLE_PAY_NATIVE)
    }

    if (applePayAvailableWeb) {
      allPaymentMethods.push(APPLE_PAY_WEB)
    }

    if (googlePayAvailableWeb) {
      allPaymentMethods.push(GOOGLE_PAY_WEB)
    }

    if (googlePayAvailableNative) {
      allPaymentMethods.push(GOOGLE_PAY_NATIVE)
    }

    setPaymentMethods(allPaymentMethods)
  }, [
    applePayAvailableNative,
    applePayAvailableWeb,
    googlePayAvailableWeb,
    googlePayAvailableNative,
    initialPaymentMethods,
    setPaymentMethods,
    excludeDigitalWallets,
  ])

  const openAddNewPaymentForm = async (e: React.MouseEvent) => {
    e.stopPropagation()
    closeAllModals()

    // istanbul ignore else
    if (onAddingNewPaymentMethod) {
      onAddingNewPaymentMethod()
    }

    return router.push({
      pathname: onlyCreditCard
        ? '/account/add-credit-card'
        : '/account/add-payment',
      query: {
        userId: profile?.id,
      },
    })
  }

  // getting default payment method
  useEffect(() => {
    if (value) {
      return
    }

    const allPaymentMethods: UserPaymentMethod[] = paymentMethods

    const paymentMethod = getPaymentMethod(
      allPaymentMethods,
      defaultPaymentMethodId
    )

    if (paymentMethod) {
      // forcing re-render with shouldValidate
      setValue(name, paymentMethod, { shouldValidate: true })
    }
  }, [paymentMethods, value, setValue, name, defaultPaymentMethodId])

  const createOptions = () => {
    const allPaymentMethods = sortPaymentMethods(
      paymentMethods,
      defaultPaymentMethodId
    ).map((payment) => ({
      key: payment.paymentId,
      name: (
        <PaymentMethod
          css={customStyling}
          key={payment.paymentId}
          {...payment}
          hideNextIcon
          isDefault={isDefaultPaymentMethod(payment, defaultPaymentMethodId)}
        />
      ),
      value: {
        ...payment,
        type: payment.type as string,
      },
    }))

    const newPaymentMethod = {
      key: 'new_payment',
      name: (
        <AddNewPaymentButton onClick={openAddNewPaymentForm}>
          <Icon.Plus />
          {onlyCreditCard ? (
            <div>New credit card</div>
          ) : (
            <div>New payment method</div>
          )}
        </AddNewPaymentButton>
      ),
      value: {
        paymentId: 'new_payment',
        name: 'New payment',
        displayName: '',
        type: 'new',
      },
      disable: true,
    }

    return allPaymentMethods.concat(newPaymentMethod)
  }

  const renderSuffix = () => {
    const hasPaymentSelected = !isEmpty(paymentMethods) && !!value

    if (hasPaymentSelected) {
      return (
        <Icon.Aura css={{ color: '$grey600' }}>
          <Icon.ChevronDown size={16} />
        </Icon.Aura>
      )
    }
  }

  return (
    <div aria-label="payment-selector">
      <FormSelectModal
        returnModalId={(id) => (modalId.current = id)}
        css={buttonStyling}
        iconSuffix={renderSuffix()}
        name={name}
        placeholder={
          <PlaceholderButton
            onClick={openAddNewPaymentForm}
            css={{ display: 'flex', alignItems: 'center' }}
            data-testid="add-payment-method-button"
          >
            <Icon.Inliner css={{ marginRight: '$xxxs' }}>
              <Icon.Plus />
            </Icon.Inliner>
            {onlyCreditCard ? (
              <div>New credit card</div>
            ) : (
              <div>New payment method</div>
            )}
          </PlaceholderButton>
        }
        label={<ModalHeader>Payment Method</ModalHeader>}
        submitLabel="Confirm"
        options={createOptions()}
        formOptions={options}
      />
      {Object.keys(errors).map(
        (key) =>
          key === name && (
            <ErrorMessage aria-label="error-detail" key={key}>
              {errors[key]?.message}
            </ErrorMessage>
          )
      )}
    </div>
  )
}

// we need to wrap it with StripeProvider to so the child component can use stripe
const PaymentMethodSelectorWrapper = (props: PaymentMethodSelectorProps) => (
  <StripeProvider stripe={stripePromise}>
    <PaymentMethodSelector {...props} />
  </StripeProvider>
)

export default PaymentMethodSelectorWrapper

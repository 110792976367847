import { SVGAttributes } from 'react'

export const Pin = ({
  size = 24,
  ...props
}: SVGAttributes<SVGElement> & { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_9941_50506)">
      <path
        d="M12.1755 24C11.4755 24 10.9755 23.5 10.9755 22.8V18.6H4.57549C3.87549 18.6 3.37549 18.1 3.37549 17.4V15.5C3.37549 14.9 3.57549 14.3 3.87549 13.7C4.17549 13.2 4.67549 12.7 5.27549 12.5L7.17549 11.5C7.37549 11.4 7.47549 11.3 7.57549 11.2C7.67549 11 7.67549 10.9 7.67549 10.7V6.7C6.77549 6.7 6.07549 6.3 5.37549 5.7C4.87549 5.1 4.47549 4.3 4.47549 3.4C4.47549 2.5 4.87549 1.7 5.47549 1C6.07549 0.4 6.97549 0 7.87549 0H16.4755C17.3755 0 18.2755 0.4 18.8755 1C19.4755 1.6 19.8755 2.5 19.8755 3.4C19.8755 4.3 19.5755 5.1 18.8755 5.8C18.2755 6.4 17.4755 6.8 16.5755 6.8V10.7C16.5755 10.9 16.5755 11.1 16.6755 11.2C16.7755 11.4 16.8755 11.5 17.0755 11.6L18.9755 12.6C19.5755 12.9 19.9755 13.3 20.3755 13.8C20.6755 14.3 20.8755 14.9 20.8755 15.6V17.5C20.8755 18.2 20.3755 18.7 19.6755 18.7H13.2755V22.9C13.3755 23.5 12.7755 24 12.1755 24ZM5.77549 16.2H18.4755V15.5C18.4755 15.3 18.4755 15.1 18.3755 15C18.2755 14.8 18.1755 14.7 17.9755 14.6L16.0755 13.6C15.4755 13.3 15.0755 12.9 14.6755 12.4C14.3755 11.9 14.1755 11.3 14.1755 10.6V5.5C14.1755 4.8 14.6755 4.3 15.3755 4.3H16.4755C16.7755 4.3 16.9755 4.2 17.1755 4C17.3755 3.9 17.4755 3.6 17.4755 3.4C17.4755 3.1 17.3755 2.9 17.1755 2.7C16.9755 2.5 16.7755 2.4 16.4755 2.4H7.87549C7.57549 2.4 7.37549 2.5 7.17549 2.7C6.97549 2.9 6.87549 3.1 6.87549 3.4C6.87549 3.7 6.97549 3.9 7.17549 4.1C7.37549 4.2 7.57549 4.3 7.87549 4.3H8.97549C9.67549 4.3 10.1755 4.8 10.1755 5.5V10.6C10.1755 11.2 9.97549 11.8 9.67549 12.4C9.37549 12.9 8.87549 13.4 8.27549 13.6L6.37549 14.6C6.17549 14.7 6.07549 14.8 5.97549 15C5.87549 15.2 5.87549 15.3 5.87549 15.5V16.2H5.77549Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9941_50506">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.125488)"
        />
      </clipPath>
    </defs>
  </svg>
)

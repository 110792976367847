import { styled } from 'styles/stitches.config'

export const Content = styled('div', {
  cursor: 'default',
	display: 'inline-block',
	filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25))',
  maxWidth: '100%',
  overflow: 'hidden',
  padding: '$s',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  width: '$applicationWidth',

  variants: {
    hAlign: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    vAlign: {
      bottom: {
        verticalAlign: 'bottom',
      },
      center: {
        verticalAlign: 'middle',
      },
      middle: {
        verticalAlign: 'middle',
      },
      top: {
        verticalAlign: 'top',
      },
    },
  },
})

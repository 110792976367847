import { useCallback } from 'react'
import { cartState } from 'config/states'
import { useRouter } from 'next/router'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { logError } from 'utils/honeybadger'
import useProfile from './useProfile'
import { useIdempotencyKey } from './useIdempotencyKey'

const useEventErrorHandler = () => {
  const router = useRouter()
  const [profile] = useProfile()
  const resetCart = useResetRecoilState(cartState)
  const [cart] = useRecoilState(cartState)
  const { clearIdempotencyKey } = useIdempotencyKey()

  const handleError = useCallback((error: unknown) => {
    clearIdempotencyKey()
    resetCart()
    logError(error as Error, { url: router.asPath, userId: profile?.id, user_cart_data: cart, user_data: profile })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error?.response?.status === 401) {
      router.push({
        pathname: '/sign-in/getting-started',
      }).catch(logError)

      return
    }

    router.push({
      pathname: '/lp/error',
    }).catch(logError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, profile, resetCart, router])

  return {
    handleError,
  }
}

export default useEventErrorHandler

import dynamic from 'next/dynamic'

const Nav = dynamic(() => import('components/Nav'), {
  ssr: false,
})

import type * as Stitches from '@stitches/react'
import { useLayoutEffect, useRef, useState } from 'react'
import { styled } from 'styles/stitches.config'
import { Header, Section as DefaultSection, Main } from './styles'
import type { CtaLayoutProps } from './types'

const Section = styled(DefaultSection, {
  // 24 padding
  paddingBottom:
    'calc(var(--joe-sizes-navHeight) + var(--joe-sizes-buttonLargeHeight) + 24px)',

  variants: {
    disableNav: {
      true: {
        paddingBottom: 74,
      },
    },
    withSubtotal: {
      true: {
        // IMO, we should get right of this. Love, Matt;
        // 24 padding, 12 text margin-bottom, 30 text height,
        paddingBottom:
          'calc(var(--joe-sizes-navHeight) + var(--joe-sizes-buttonLargeHeight) + 12px + 30px + 24px)',
      },
    },
  },
})

const CtaContainer = styled('div', {
  backgroundColor: '$white',
  bottom: 'var(--joe-sizes-navHeight)',
  boxShadow: '$top',
  maxWidth: '100%',
  padding: '$s $s $xv $s',
  position: 'fixed',
  width: '$applicationWidth',

  variants: {
    disableNav: {
      true: {
        bottom: 0,
      },
    },
    noShadow: {
      true: {
        backgroundColor: '$grey50',
        boxShadow: 'none',
      },
    },
  },
})

export const CtaPage = ({
  children,
  ctaComponent,
  headerComponent,
  headerVariant,
  mainAriaLabel,
  overrideMainCSS,
  ctaNoShadow,
  ...props
}: CtaLayoutProps & Stitches.VariantProps<typeof Section>) => {
  const CtaRef = useRef<HTMLDivElement | null>(null)
  const [ctaPaddingBottom, setCtaPaddingBottom] = useState(96)

  const ctaComponentExists = ctaComponent && ctaComponent() !== null

  useLayoutEffect(() => {
    // istanbul ignore if
    if (CtaRef.current?.clientHeight) {
      setCtaPaddingBottom(CtaRef.current?.clientHeight + 96)
    }
  }, [CtaRef.current?.clientHeight])

  return (
    <Section
      data-testid="cta-page-component"
      header={headerVariant}
      {...props}
      css={{ paddingBottom: ctaPaddingBottom }}
    >
      {headerComponent && <Header>{headerComponent()}</Header>}

      <Main aria-label={mainAriaLabel} css={overrideMainCSS}>
        {children}
      </Main>

      {ctaComponentExists && (
        <CtaContainer disableNav={props.disableNav} noShadow={ctaNoShadow} ref={CtaRef}>
          {ctaComponent()}
        </CtaContainer>
      )}

      {!props.disableNav && (
        <Nav />
      )}
    </Section>
  )
}
